//Aboutus.jsx

import React, { useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './About_us.css';
import Helmet from 'react-helmet';
import { checkAndUpdateLanguage } from '../i18n/i18n';

const Aboutus = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = checkAndUpdateLanguage(i18n.language);
    console.log('About Us component rendered. Current language:', language);

    const handleLanguageChange = () => {
      console.log('Language change event detected in About Us');
      checkAndUpdateLanguage(i18n.language);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        {/* ... Helmet content ... */}
        <title>{t('About Us - Our Mission and Approach')}</title>
        <meta name="description" content={t('our mission to leverage AI for the betterment of society and our approach to AI in business.')} />
        <meta name="keywords" content={t('AI mission, AI society, AI business, ethical AI, AI research, AI development')} />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content={t('THAILAND.AI - About Us - Our Mission and Approach')} />
        <meta property="og:description" content={t('Creating an AI Transformation Platform dedicated to improving people\'s lives and benefiting society, this platform will serve as a connecting hub for technology, society, and business.')} />
        <meta property="og:image" content="https://www.thailand.ai/thailand01.png" />
        <link rel="apple-touch-icon" href="https://www.thailand.ai/thailand01.png" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      </Helmet>

      <Container>
        <div className="separator" />

        <h1 className="text-left">{t('About Us')}</h1>

        <div className="separator" />

        <Card className="fixed-width-card">
          <Card.Body>
            <Card.Title className="custom-card-title">{t('Our mission')}</Card.Title>
            <Card.Text className="custom-card-text">
              {t('To leverage A.I. for the improvement of people\'s lives and the benefit of society.')}
            </Card.Text>
            <div className="custom-card-divider"></div>

            <Card.Title className="custom-card-title">{t('A.I. Platform')}</Card.Title>
            <Card.Text className="custom-card-text">
              {t('We are building an A.I. platform dedicated to improving people\'s lives and benefiting society.')}
            </Card.Text>
            <div className="custom-card-divider"></div>

            <Card.Title className="custom-card-title">{t('Email')}</Card.Title>
            <Card.Text className="custom-card-text">
              <a
                style={{ textDecoration: 'none' }}
                href="mailto:enquiry@thailand.ai"
              >
                enquiry@thailand.ai
              </a>
            </Card.Text>
          </Card.Body>
        </Card>

        <div className="separator" />
      </Container>
    </>
  );
};

export default Aboutus;